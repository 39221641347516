import React, { useState, useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { Card, CardBody, CardHeader } from "reactstrap";
import Datatable from "../common/datatable";
import styles from "../donate/donate.module.scss";
import matchesFormatter from "../../helpers/matchesFormatter";
import Text from "../common/text";
import MatchStatusSelect from "./MatchStatusSelect";
import LabelStatusSelect from "./LabelStatusSelect";
import RetailerSelect from "../retailers/retailer-select";
import NonprofitSelect from "../nonprofits/nonprofit-select";
import { XCircle } from "react-feather";

const ADMIN_GET_MATCHES = gql`
  query ($input: AdminGetMatchesInput!) {
    adminGetMatches(input: $input) {
      hasMore
      total
      matches {
        uuid
        status
        createdDate
        retailer {
          name
          uuid
        }
        user {
          uuid
          firstName
        }
        nonprofit {
          organizationName
          uuid
        }
        sourceLocation {
          address {
              streetAddress1
              city
              state
              zip
              countryCode
          }
          contactEmail
          contactNumber
        }
        items {
          product {
            uuid
            slug
            title
            images {
              uuid
              resolutions {
                xs {
                  url
                  size
                }
              }
            }
          }
        }
      }
    }
  }
`;

const MatchesList = ({
  title,
  hideStatusFilter,
  hideLabelStatusFilter,
  hideRetailerSearch,
  hideNonprofitSearch,
  nonprofitUUID,
  retailerUUID,
  productUUID,
}) => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [matchStatus, setMatchStatus] = useState([]);
  const [selectLabelOpen, setSelectLabelOpen] = useState(false);
  const [labelStatus, setLabelStatus] = useState([]);
  const [matches, setMatches] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [term, setTerm] = useState({ real: "", debounced: "" });
  const [limit, setLimit] = useState(10);
  const [indexFrom, setIndexFrom] = useState(0);
  const [retailer, setRetailer] = useState(null);
  const [nonprofit, setNonprofit] = useState(null);

  const MATCHES_VARIABLES = {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        nonprofitUUID: nonprofit?.uuid,
        retailerUUID: retailer?.uuid,
        productUUID: productUUID,
        statuses: matchStatus,
        labelStatuses: labelStatus,
        term: term.debounced,
        limit: limit,
        indexFrom: indexFrom,
      },
    },
  };
  const [adminGetMatches, { loading, error, data: _data }] = useLazyQuery(
    ADMIN_GET_MATCHES,
    MATCHES_VARIABLES,
  );

  const onChangePage = (newPage, _) => {
    setIndexFrom((newPage - 1) * limit);
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    setIndexFrom((page - 1) * newPerPage);
    setLimit(newPerPage);
  };

  useEffect(() => {
    if (retailerUUID) {
      setRetailer({ uuid: retailerUUID });
    }

    if (nonprofitUUID) {
      setNonprofit({ uuid: nonprofitUUID });
    }
    adminGetMatches();
  }, [indexFrom, nonprofitUUID, retailerUUID, matchStatus]);

  useEffect(() => {
    if (!loading) {
      if (_data && _data.adminGetMatches) {
        setMatches(matchesFormatter(_data?.adminGetMatches?.matches));
        setTotalRows(_data?.adminGetMatches.total);
      } else {
        setMatches([]);
      }
    }
  }, [loading]);

  const toggleSelect = () => setSelectOpen((val) => !val);
  const toggleLabelSelect = () => setSelectLabelOpen((val) => !val);

  const hideAll = () => {
    return (
      !!hideStatusFilter &&
      !!hideRetailerSearch &&
      !!hideNonprofitSearch &&
      !!hideLabelStatusFilter
    );
  };

  const handleSearch = (e) => {
    const val = e.target.value;
    term.real = val;
    setTerm({ ...term });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      term.debounced = e.target.value;
      setTerm({ ...term });
    }
  };

  const clearSearch = () => {
    setTerm({ real: "", debounced: "" });
  };

  return (
    <>
      <Card className={styles.donateItemEdit}>
        <h3>{title}</h3>
        {!hideAll() && (
          <CardHeader>
            <div className="d-flex align-items-center">
              {!hideStatusFilter && (
                <MatchStatusSelect
                  isOpen={selectOpen}
                  placeholder="Filter By status"
                  setValues={setMatchStatus}
                  values={matchStatus}
                  style={{
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  }}
                  toggle={toggleSelect}
                />
              )}

              {!hideLabelStatusFilter && (
                <LabelStatusSelect
                  isOpen={selectLabelOpen}
                  placeholder="Filter by label status"
                  setValues={setLabelStatus}
                  values={labelStatus}
                  style={{
                    width: "300px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                  }}
                  toggle={toggleLabelSelect}
                />
              )}

              {!hideRetailerSearch && (
                <RetailerSelect
                  {...{
                    retailer: {
                      uuid: retailer?.uuid,
                      name: retailer?.name,
                    },
                    style: {
                      width: "300px",
                      marginLeft: "20px",
                      height: "38px",
                      overflow: "hidden",
                    },
                    onSelect: (r) => {
                      setIndexFrom(0);
                      setRetailer(r);
                    },
                  }}
                />
              )}

              {!hideNonprofitSearch && (
                <NonprofitSelect
                  {...{
                    nonprofit: {
                      uuid: nonprofit?.uuid,
                      name: nonprofit?.name,
                    },
                    style: {
                      width: "300px",
                      marginLeft: "20px",
                      height: "38px",
                      overflow: "hidden",
                    },
                    onSelect: (np) => {
                      setIndexFrom(0);
                      setNonprofit(np);
                    },
                  }}
                />
              )}
            </div>
            <div
              className="flex align-items-center mt-3 ml-3"
              {...{
                style: {
                  width: "300px",
                },
              }}
            >
              <input
                className={styles.searchInput}
                placeholder="Tracking No."
                onChange={handleSearch}
                value={term.real}
                onKeyDown={handleKeyDown}
              />
              {term?.debounced?.length ? (
                <XCircle
                  className="cursor-pointer"
                  size={30}
                  onClick={clearSearch}
                />
              ) : null}
            </div>
          </CardHeader>
        )}
        <CardBody className="match-datatable">
          {error ? (
            <>Some Error Occured! Try Reloading the page.</>
          ) : matches ? (
            <Datatable
              hideActions={true}
              myData={matches}
              loading={loading}
              pageSize={limit}
              pagination={true}
              paginationServer={true}
              totalRows={totalRows}
              myClass="-striped -highlight"
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          ) : (
            <Text>No matches</Text>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default MatchesList;
