import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import itemsFormatter from "./itemsFormatter";

const mapStatusToBadge = {
  fulfilled: "badge-success",
  created: "badge-secondary",
  cancelled: "badge-danger",
};
const mapTypeToBadge = {
  retailer: "badge-success",
  individual: "badge-secondary",
};

const getItems = (match) => {
  const allImgs = [];
  allImgs.push(...itemsFormatter(match.items));
  return allImgs;
};

const getDonor = (match) => {
  const retailers = [];
  if (match.retailer) {
    retailers.push(
      <Link
        key={match.retailer.uuid}
        target="_blank"
        to={`/retailers/details/${match.retailer.uuid}`}
      >
        {match.retailer.name} &nbsp;&nbsp;
        <span className={`badge ${mapTypeToBadge["retailer"]}`}>Retailer</span>
      </Link>,
    );
  } else {
    retailers.push(
      <Link
        key={match.user?.uuid}
        target="_blank"
        to={`/users/search?uuid=${match.user?.uuid}`}
      >
        {match.user?.firstName}&nbsp;{match.user?.lastName}&nbsp;
        <span className={`badge ${mapTypeToBadge["individual"]}`}>
          Individual
        </span>
      </Link>,
    );
  }
  return retailers;
};

const getNonprofit = (match) => {
  const nonprofits = [];
  if (match.nonprofit) {
    nonprofits.push(
      <Link
        key={match.nonprofit.uuid}
        target="_blank"
        to={`/nonprofit/${match.nonprofit.uuid}`}
      >
        {match.nonprofit.organizationName} &nbsp;&nbsp;
      </Link>,
    );
  }
  return nonprofits;
};

const matchesFormatter = (matchesArr) => {
  if (!matchesArr?.length) {
    return;
  }
  const formattedMatches = matchesArr.map((match) => {
    return {
      date_created: (
        <Link
          key={match.uuid}
          target="_blank"
          to={`/match/${match.uuid}`}
        >
          {moment(match.createdDate).format("DD MMM, YYYY hh:mm:SS A")}
        </Link>
      ),
      items: getItems(match),
      match_status: (
        <span
          key={match.uuid}
          className={`badge ${mapStatusToBadge[match.status]}`}
        >
          {match.status}
        </span>
      ),
      nonprofit: getNonprofit(match),
      donor: getDonor(match),
    };
  });
  return formattedMatches;
};

export default matchesFormatter;
