import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import MatchCard from "./MatchCard";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { List } from "react-feather";
import TransactionList from "../transactions/transaction-list";
import MatchUpdatesList from "../matches/update/matchUpdatesList";
import LabelUpdatesList from "../matches/update/labelUpdatesList";
import { gql, useQuery } from "@apollo/client";

const ADMIN_GET_MATCH = gql`
    query($input: AdminGetMatchInput) {
        adminGetMatch(input: $input) {
            uuid
            status
            createdDate
            retailer {
                name
                uuid
            }
            user {
              uuid
              firstName
            }
            nonprofit {
              organizationName
              uuid
            }
            labels {
              url
              trackingNumber
              status
              labelUpdates {
                status
                description
                createdDate
              }
            }
            sourceLocation {
                address {
                    streetAddress1
                    city
                    state
                    zip
                    countryCode
                }
                contactEmail
                contactNumber
            }
            matchUpdates {
              status
              createdDate
            }
            items {
                uuid
                quantity
                product {
                    uuid
                    slug
                    title
                    images {
                        uuid
                        resolutions {
                          s {
                              url
                          }
                          l {
                              url
                          }
                        }
                    }
                }
                itemPreference {
                    receiveAgain
                }
                feedback {
                  condition
                  notes
                  images {
                    uuid
                    alt
                    resolutions {
                      s {
                          url
                      }
                    }
                  }
                }
            }
        }
    }
`;

const MatchDetails = () => {
  const { uuid } = useParams();

  const { loading, data } = useQuery(ADMIN_GET_MATCH, {
    fetchPolicy: "no-cache",
    variables: {
      input: {
        matchUUID: uuid,
      },
    },
  });

  return (
    <Fragment>
      <Breadcrumb
        title="Match Details"
        parent="Dashboard"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {loading ? (
              <div className="text-center my-5">
                <Spinner />
              </div>
            ) : (
              <MatchCard match={data?.adminGetMatch} />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Tabs>
                  <TabList className="nav nav-tabs tab-coupon">
                    <Tab className="nav-link d-flex align-items-center">
                      <List className="mr-2" />
                      Transactions
                    </Tab>
                    <Tab className="nav-link d-flex align-items-center">
                      <List className="mr-2" />
                      Match Updates
                    </Tab>
                    <Tab className="nav-link d-flex align-items-center">
                      <List className="mr-2" />
                      Label Updates
                    </Tab>
                  </TabList>
                  <TabPanel>
                    {uuid && data?.adminGetMatch?.retailer?.uuid && (
                      <TransactionList
                        matchUUID={uuid}
                        retailerUUID={data?.adminGetMatch?.retailer?.uuid}
                        hideRetailerSearch={true}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {data?.adminGetMatch?.matchUpdates && (
                      <MatchUpdatesList
                        matchUpdates={data?.adminGetMatch?.matchUpdates}
                      />
                    )}
                  </TabPanel>
                  <TabPanel>
                    {data?.adminGetMatch?.labels && (
                      <LabelUpdatesList labels={data?.adminGetMatch?.labels} />
                    )}
                  </TabPanel>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default MatchDetails;
