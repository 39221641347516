import React from "react";
import s from "../sales/orders/components/OrderDetails.module.scss";
import { Card, CardBody, Col, Row } from "reactstrap";
import { OnGoingMatchStatuses } from "src/constants/matchStatus";
import Text from "../common/text";
import TransferMatch from "./TransferMatch";

const MatchCard = ({ match }) => {
  return (
    <Card>
      <CardBody>
        <>
          <Row>
            <Col
              xs="12"
              md="6"
            >
              <p
                style={{ fontWeight: 700, textTransform: "uppercase" }}
                className={`text-default font`}
              >
                MATCH# {match?.uuid}
              </p>
            </Col>
            <Col
              md="6"
              className="d-flex align-items-start justify-content-end"
            >
              <p
                style={{ fontWeight: 700, textTransform: "uppercase" }}
                className={`text-default font ${
                  OnGoingMatchStatuses.includes(match?.status) && "c-crimson"
                }`}
              >
                {match?.status}
              </p>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col
              xs="12"
              md="4"
            >
              <h6 className="font-weight-bold card-title c-black">NONPROFIT</h6>
              <h5>
                <a
                  style={{
                    color: "tomato",
                    letterSpacing: -0.1,
                  }}
                  href={"/nonprofit/" + match?.nonprofit?.uuid}
                >
                  <u>{match?.nonprofit?.organizationName}</u>
                </a>
              </h5>
            </Col>
            <Col
              xs="12"
              md="4"
            >
              <h6 className="font-weight-bold card-title c-black">DONOR</h6>
              <h5>
                {match.retailer && (
                  <a
                    style={{
                      color: "tomato",
                      letterSpacing: -0.1,
                    }}
                    href={"/retailers/details/" + match?.retailer?.uuid}
                  >
                    <u>{match?.retailer?.name}</u>
                  </a>
                )}
                {match.user && (
                  <a
                    style={{
                      color: "tomato",
                      letterSpacing: -0.1,
                    }}
                    href={"/users/search?uuid=" + match?.user?.uuid}
                  >
                    <u>{match?.user?.firstName}</u>
                  </a>
                )}
              </h5>
            </Col>
            <Col
              xs="12"
              md="4"
            >
              <h6 className="font-weight-bold card-title c-black">LABEL</h6>

              {match?.labels?.map((label, index) => (
                <h5 key={index}>
                  <a
                    style={{
                      color: "tomato",
                      letterSpacing: -0.1,
                    }}
                    rel="noreferrer"
                    target="_blank"
                    href={label.url}
                  >
                    <u>{label.trackingNumber}</u>
                  </a>
                  <span>&nbsp;({label.status})</span>
                </h5>
              ))}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              {match?.items?.map(
                ({
                  product,
                  quantity,
                  uuid: puuid,
                  itemPreference,
                  feedback,
                }) => (
                  <>
                    <Row key={puuid}>
                      <Col
                        xs="8"
                        md="4"
                      >
                        <div
                          className="d-flex mb-2"
                          key={puuid}
                        >
                          <div className={s.imgWrap}>
                            <a href={"/product/" + product.uuid}>
                              <img
                                src={
                                  product.images &&
                                  product.images.length > 0 &&
                                  product.images[0].resolutions.s.url
                                }
                                alt={product?.title}
                              />
                            </a>
                          </div>
                          <div>
                            <p className="text-default mb-2">
                              <a href={"/product/" + product.uuid}>
                                <b>
                                  <u>{product?.title}</u>
                                </b>
                              </a>
                            </p>
                            {product?.fairMarketValue && (
                              <p className="text-default mb-2">
                                <span>
                                  Total FMV: &nbsp;
                                  {`$`}
                                  {product?.fairMarketValue?.value * quantity}
                                </span>
                              </p>
                            )}
                            <p className="info text-default font-weight-bold">
                              X{quantity}
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs="4"
                        md="4"
                      >
                        {itemPreference !== null && (
                          <>
                            <h6 className="font-weight-bold card-title c-black">
                              ITEM PREFERENCE
                            </h6>
                            <h5>
                              <u>Receive Again:</u>{" "}
                              {itemPreference?.receiveAgain ? "Yes" : "No"}
                            </h5>
                          </>
                        )}
                      </Col>
                      <Col
                        xs="12"
                        md="4"
                      >
                        {feedback !== null && (
                          <>
                            <h6 className="font-weight-bold card-title c-black">
                              FEEDBACK
                            </h6>
                            <Text>
                              <u>Notes:</u> {feedback?.notes}
                            </Text>
                            <Text>
                              <u>Condition:</u> {feedback?.condition}
                            </Text>
                            <Row>
                              {feedback?.images?.map((img) => (
                                <Col key={img.uuid}>
                                  <div className={s.imgWrap}>
                                    <img
                                      src={img && img.resolutions.s.url}
                                      alt={img.alt}
                                    />
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                    <hr />
                  </>
                ),
              )}
            </Col>
          </Row>
          <Row>
            <TransferMatch matchUUID={match?.uuid} />
          </Row>
        </>
      </CardBody>
    </Card>
  );
};
export default MatchCard;
