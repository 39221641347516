import { gql } from "@apollo/client";
import React, { useState } from "react";
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import LoadingButton from "../common/loadingButton";
import { initializeApollo } from "src/helpers/apollo";
import { toast } from "react-toastify";
import { err_toast } from "src/helpers/errors";
import cx from "classnames";
import scss from "./styles.module.scss";
import NonprofitSelect from "../nonprofits/nonprofit-select";

const ADMIN_TRANSFER_MATCH = gql`
    mutation adminTransferMatch($input: AdminTransferMatchInput!) {
        adminTransferMatch(input: $input) {
            uuid
        }
    }
`;

const TransferMatch = ({ matchUUID }) => {
  const [open, setOpen] = useState(false);
  const [trackingNumber, setTrackingNumber] = useState(null);

  const [nonprofit, setNonprofit] = useState(null);

  const [carrier, setCarrier] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const toggleDropdown = () => setOpenDropdown((val) => !val);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  let client = initializeApollo();

  const transferMatch = () => {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: ADMIN_TRANSFER_MATCH,
          variables: {
            input: {
              matchUUID: matchUUID,
              nonprofitUUID: nonprofit?.uuid,
              trackingNumber: trackingNumber,
              carrier: carrier,
            },
          },
        })
        .then((result) => {
          setTimeout(() => {
            resolve("done");
            toast.success("Transferred match");
            window.location.replace(
              `/match/${result?.data?.adminTransferMatch?.uuid}`,
            );
          }, 1000);
        })
        .catch((e) => {
          reject(e);
          err_toast(e);
        });

      setTimeout(() => resolve("done"), 2000);
    });
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      <Button
        color="primary"
        onClick={onOpenModal}
      >
        Transfer Match
      </Button>
      <Modal
        isOpen={open}
        toggle={onCloseModal}
        size="lg"
      >
        <ModalHeader>
          <span>Transfer Match</span>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Nonprofit</Label>
              <NonprofitSelect
                {...{
                  nonprofit: {
                    uuid: nonprofit?.uuid,
                    name: nonprofit?.name,
                  },
                  style: {
                    width: "400px",
                    marginLeft: "20px",
                    height: "38px",
                    overflow: "hidden",
                    marginBottom: "20px",
                  },
                  onSelect: (n) => {
                    setNonprofit(n);
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Tracking Number</Label>
              <Input
                name="trackingNumber"
                type="text"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Label>Carrier</Label>
              <Dropdown
                isOpen={openDropdown}
                toggle={toggleDropdown}
                className={cx(scss.select, "mt-2")}
              >
                <DropdownToggle className={scss.dropdown}>
                  <div className="d-flex flex-wrap">
                    {!carrier ? (
                      <span>Select a carrier</span>
                    ) : (
                      <p>{carrier}</p>
                    )}
                  </div>
                </DropdownToggle>
                <DropdownMenu className={scss.menu}>
                  {[
                    { label: "USPS", value: "USPS" },
                    { label: "FedEx", value: "FedEx" },
                    { label: "UPS", value: "UPS" },
                  ].map(({ label, value }) => (
                    <p
                      style={{
                        color: carrier === value && "#90A5A1",
                      }}
                      key={value}
                      id={value}
                      onClick={() => {
                        setCarrier(value);
                        toggleDropdown();
                      }}
                    >
                      {label}
                    </p>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <LoadingButton
            text="Save"
            onClick={transferMatch}
            color="primary"
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default TransferMatch;
